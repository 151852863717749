html{
    height: 100%;
    body { 
        background-color: #fefefe;
        text-align: center;
        height: 100%;

        #application {
            height: 100%;
        }
        .navbar {
        background: #21374B;
        color: #E7DACB;
        height: 50px;
        }
        .card {
            margin-top: .5em;
        }
        .bottomMenu {
            border-top: #f0f0f0 1px solid;
            width: 100%;
            position: fixed;
            bottom: 0;
        }
        h1 {
            font-size: 40px;
            margin-top: 30px;
        }
    }
}